import http from "@/http/http-common";
const resource = "/api/frontend";
class NewsService {
    getdetailNew(id) {
        return http.get(`${resource}/detailNews?id=${id}`);
    }
    getNewsSlug(slug) {
        return http.get(`${resource}/newsByCategoryWithPaginate?slug=${slug}`);
    }
    getCategorySlug(slug) {
        return http.get(`${resource}/getCategoryBySlug?slug=${slug}`);
    }
    categories() {
        return http.get(`${resource}/categories`);
    }
    pageNews() {
        return http.get(`${resource}/pageNews`);
    }
}

export default new NewsService();