<template>
  <div class="promos">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <router-link class="before" :to="'/'">Trang chủ</router-link>
      <span class="tag-mid">/</span>
      <router-link class="before" :to="'/news'">Tin Tức & Sự Kiện</router-link>
      <span class="tag-mid">/</span>
      <router-link
        v-if="category"
        class="after"
        :to="'/news/' + category?.slug"
        >{{ category[locale]?.name }}</router-link
      >
    </div>
    <div class="promos-main">
      <div class="promos-main__title" v-if="category">
        <h5>{{ category[locale]?.name }}</h5>
        <h1>{{ category[locale]?.content }}</h1>
      </div>
      <div class="promos-main__content">
        <div class="sale-info">
          <div class="sale-info__left" v-if="newsFist">
            <div class="item">
              <router-link
                :to="
                  '/news/' +
                  category.slug +
                  '/' +
                  newsFist.slug +
                  '?id=' +
                  newsFist.id
                "
              >
                <div class="item__bg">
                  <img :src="newsFist?.image" alt="" />
                </div>
                <div class="item__content">
                  <h5 class="category-post">
                    {{ newsFist?.category[locale]?.name }}
                  </h5>
                  <h3 class="title">
                    {{ newsFist[locale]?.title }}
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="sale-info__right" v-if="newList && newList.length > 0">
            <div class="item" v-for="(item, index) in newList" :key="index">
              <router-link
                :to="
                  '/news/' + category.slug + '/' + item.slug + '?id=' + item.id
                "
              >
                <div class="item__bg">
                  <img :src="item?.image" alt="" />
                </div>
                <div class="item__content">
                  <h5 class="category-post">
                    {{ item.category[locale]?.name }}
                  </h5>
                  <h3 class="title">
                    {{ item[locale]?.title }}
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="promos-main__other" v-if="newOther && newOther.length > 0">
        <div class="item" v-for="(item, index) in newList" :key="index">
          <router-link
            :to="'/news/' + category.slug + '/' + item.slug + '?id=' + item.id"
          >
            <div class="item__bg">
              <img :src="item?.image" alt="" />
            </div>
            <div class="item__content">
              <h5 class="category-post">{{ item.category[locale]?.name }}</h5>
              <h3 class="title">
                {{ item[locale]?.title }}
              </h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsService from "@/services/NewsService";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      dataNews: "",
      newsFist: "",
      newList: [],
      newOther: [],
      category: "",
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // console.log(this.$route.params.slug);
    this.getDataSlug();
    this.getCategory();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  // watch: {
  //   "$route.params"() {
  //     this.getDataSlug();
  //     this.getCategory();
  //   },
  // },
  methods: {
    // ...mapActions(["setMenu"]),
    getDay(day) {
      const arr = day.split("-");
      return arr[0] + " Tháng " + arr[1] + " năm " + arr[2];
    },
    async getDataSlug() {
      await NewsService.getNewsSlug('tin-khuyen-mai')
        .then((resp) => {
          this.dataNews = resp.data.data;
          if (this.dataNews?.length > 0) {
            this.newsFist = this.dataNews[0];
          }
          if (this.dataNews?.length > 1) {
            this.newList = this.dataNews.slice(1, 3);
          }
          if (this.dataNews?.length > 3) {
            this.newOther = this.dataNews.slice(3, this.dataNews.length);
          }
        })
        .catch(() => {});
    },
    async getCategory() {
      await NewsService.getCategorySlug('tin-khuyen-mai')
        .then((resp) => {
          this.category = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
